@import "./../../scss/variables.scss";
.form-check-input[type="checkbox"] {
  color: black !important;
}
.employee-active-tab {
  background-color: white !important;
}

.employee-inactive-tab {
  background-color: #e6e6e6 !important;
}
.employee-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.employee-tabs > li {
  /* flex-grow: 33%; */
  width: 50%;
}
.employee-tabs > li > button {
  width: 100%;
  border-radius: 10px 10px 0px 0px !important;
}

.employee-table > tbody > tr > td {
  vertical-align: middle;
}
.employee-table > tbody,
thead {
  & > tr {
    border-bottom: 1px solid #e6e6e6 !important;
  }
}

.employee-details-tab {
  &:hover {
    background-color: $blue_color !important;
    color: white !important;
  }
  border-radius: 15px 0px 0px 15px !important;
  height: 30px;
}
.employee-details-tab.active {
  background-color: $blue_color !important;
  color: white !important;
}

.employee-main {
  &__header_button {
    border-radius: 30px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  &__header_button::after {
    content: "\25bc";
    font-size: 0.5rem;
    line-height: 0;
    padding-left: 5px;
  }
  &__bulk_action {
    :hover {
      background-color: $blue_color !important;
      color: white !important;
    }
    &__inactive {
      color: grey !important;
    }
  }
}
