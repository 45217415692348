@import "./../../scss/variables.scss";
.child-container,
.calendar-container {
  background-color: white;
  border-radius: 7px;
  margin: 0;
  padding: 0;
  border: 1px solid #e6e6e6;
  
}
.calendar-container {
  width: 150px;
  height: 150px;
}

.right-bar-container,
.right-bar-child {
  width: 0%;
  position: fixed;
  z-index: 2222;
  height: 100%;
  top: 0;
  right: 0;
  // background-color: $cyan-12;
  overflow-x: hidden;
  padding: 0;
  transition: 0.5s;
  opacity: 0;
}

.right-bar-child {
  display: none;
  width: 100% !important;
}

.dashboard-border{
  border: 1px solid #e6e6e6;
}
.dashboard-section-heading{
  justify-content: flex-start !important;
}