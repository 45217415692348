@import "./../../../../scss/variables.scss";
.icon-span {
  padding: 1px;
  margin-right: 4px;
  border-radius: 5px;
  transition: ease 0.5s;
}
.area-schedule {
  &-container {
    border-bottom: 3px solid var(--bs-blue_color);
    border-left: 1px solid var(--bs-blue_color);
    border-right: 1px solid var(--bs-blue_color);
  }
  &-weekly-days-header {
    color: var(--bs-blue_color);

    font-weight: bold;
    span {
      color: rgba($color: #000000, $alpha: 0.5);
    }
  }

  &-collapse-container {
    &-title {
      font-size: 13px;
      font-weight: bold;
      color: var(--bs-blue_color);

      cursor: pointer;
      span {
        @extend .icon-span;
      }
    }
    &-area-title {
      font-size: 0.9rem;
      font-weight: bold;
      cursor: pointer;
      color: rgba($color: #000000, $alpha: 0.7);

      span {
        @extend .icon-span;
      }
    }
  }
  &-plus-icon {
    color: var(--bs-blue_color);
    transition: ease 0.2s;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.area-schedule-plus-icon:hover {
  color: white;
  background-color: var(--bs-blue_color);
}
.area-schedule-collapse-container-title:hover,
.area-schedule-collapse-container-area-title:hover {
  color: rgba($color: #000000, $alpha: 0.7);
  span {
    background-color: rgba($color: #000000, $alpha: 0.7);
    color: white;
  }
}
// .area-schedule-collapse-container-area-title:hover {
//   border-bottom: 1px solid var(--bs-blue_color);
// }

.schedule-shift-item {
  border-radius: 5px;

  position: relative;
}
.schedule-shift-item:hover .schedule-shift-item-overlay {
  opacity: 1;
  display: block;
}
.schedule-shift-item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  opacity: 0;
  transition: ease 0.2s;
  display: none;
  z-index: 1;
}
.schedule-shift-item-overlay-content {
  div {
    background-color: gray;
    color: white;
    font-size: 10px;
    border-radius: 0 0 5px 5px;
    line-height: 8px;
  }
}
.shift-item-monthly-icon {
  border: 1px solid black;
  line-height: 1px;
  cursor: pointer;
}
.shift-item-monthly-icon:hover {
  border: 1px solid white;
  color: white;
}
