.weekly-table {
  text-align: center;
}
.weekly-table>thead>tr:first-child{
  border-bottom: 10px solid #e6e6e6;

}
.weekly-table > thead > tr > th,
.weekly-table > tbody > tr > td {
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 0px;
  justify-content: center;
  max-width: 200px;
  width: 180px;
}
.weekly-table > thead > tr > th:last-child,
.weekly-table > tbody > tr > td:last-child {
  border-right: none;
}

.weekly-table > thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}
.weekly-table-wrapper {
  height: 90%;
}
.weekly-table-wrapper > .table-responsive {
  height: 100%;
}
