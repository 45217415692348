@import "./../../scss/variables.scss";
.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.QS-dropdown-menu {
  transform: translate(0px, 35px) !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: $blue_color !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: $cyan-12 !important;
}
