@import "./../../scss/variables.scss";
.setting-tab {
  // border-radius: 10px 10px 0 0 !important;
  margin-right: 5px;
  border: none !important;
  height: 100% !important;
}
.inactive-setting-tab {
  color: grey;
  background-color: white !important;
}
.active-setting-tab {
  background-color: $cyan-12 !important;
  color: black;
}

.buss-setting-modal-bg {
  background-color: $cyan-12;
  width: 90vw;
  height: 70vh;
}

.business-settings-tabs > .tab-content {
  height: 100% !important;
  overflow: auto;
  padding-bottom: 40px;
}
