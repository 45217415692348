.nav-option {
  color: var(--bs-gray);
  margin-left: 15px;
  transition: ease 0.2s;
  font-size: small;
  border-bottom: 2px solid transparent;
  display: flex;
  flex-direction: row;
  align-items: center;

  &-icon {
    // color: var(--bs-blue_color);
  }
  &-text {
    // margin-left: 5px;
    text-align: center;
  }
  width: auto;
}
.nav-option:hover,
.nav-option.active {
  border-bottom: 2px solid var(--bs-blue_color);
  color: var(--bs-blue_color) !important;
}

.magic-nav-text {
  transition: visibility 0s linear 0.2s, opacity 0.2s linear, width 0.2s linear;
}
.magic-nav-text.scrolled {
  opacity: 0;
  visibility: collapse;
  width: 0px;
}
.magic-nav-text.unscrolled,
.nav-option:hover .magic-nav-text {
  opacity: 1;
  visibility: visible;
  width: 100px;
}

.popover-button {
  font-size: 14px;
  color: var(--bs-blue_color);
}
.popover-button:hover {
  color: var(--bs-blue_color);
}
