
.calendar-input{
    z-index: 999;
    position: relative;
}
.DatePicker{
    &__calendarContainer{
        z-index: 9999;
    }
}

.calendar-popover{
    max-width: none;
    border: none;
    .popover-arrow::before{
        // border-width: 0px;
        border-bottom-color: #fff;
        // background-color: red;

    }
    
}