// @import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import "./variables.scss";

.body,
html,
body {
  // margin: 0;
  // padding: 0;
  background-color: #fff !important;
  height: 100%;
  font-family: "Roboto", sans-serif !important;
}

.white-container {
  background-color: white;
  border-radius: 10px;
  margin: 0;
  padding: 10px;
}
.blue-container {
  background-color: #ecf8fe;

  border-radius: 10px;
  margin: 0;
  padding: 10px;
}

.grey-container {
  background-color: whitesmoke;
  padding: 10px;
}

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
  blue_color: $blue_color,
  green_color: $green_color,
  dark_orange: $dark_orange,
  light_orange: $light_orange,
  highlighted: $highlighted,
  plain: $plain,
  no_color: $no_color,
  cyan-12: $cyan-12,
  cyan-6: $cyan-6,
  white: $white,
  grey: $grey,
);
// :root {
//   --blue_color: #{$blue_color};
//   --highlighted: #{$highlighted};

//   --green_color: #{$green_color};
//   --dark_orange: #{$dark_orange};
//   --light_orange: #{$light_orange};
//   --plain: #{$plain};
//   --no_color: #{$no_color};
//   --cyan-12: #{$cyan-12};
//   --cyan-6: #{$cyan-6};
//   --white: #{$white};
//   --grey: #{$grey};
// }

.nav-item .nav-link::after {
  color: black !important;
}
// .customButton > div,
// .customDropdown .dropdown-toggle::after {
//   // color: white !important;
// }
// .btn-plain {
//   color: $highlighted !important;
// }

// button[class*=" btn "] > div,
// ::after {
//   color: white;
// }
button[class*=" btn-plain "] {
  div {
    color: rgba(0, 0, 0, 0.7) !important ;
  }
  &:focus {
    box-shadow: none;
  }

  background-color: transparent !important;
  border-color: transparent !important;
}
button[class*=" btn-grey "] > div {
  color: rgba(0, 0, 0, 0.7) !important ;
}
button[class*=" btn-no_color "] > div {
  color: rgba(0, 0, 0, 0.7) !important ;
}
.modal-content {
  border-radius: 10px !important;
}
.btn-dark_orange > div,
.btn-dark_orange::after {
  color: white !important;
}
.btn-blue_color > div,
button[class*=" btn-blue_color "] {
  color: white !important;
}
.btn-blue_color {
  color: white !important;
}
.btn-blue_color::after {
  color: white !important;
}

.btn-cyan-12 > div > div {
  color: rgba(0, 0, 0, 0.7) !important ;
}
.btn-cyan-6 > div > div {
  color: rgba(0, 0, 0, 0.7) !important ;
}

.btn-no_color:hover {
  background-color: none !important;
  border-color: none !important;
}
a {
  text-decoration: none !important;
  // color: black !important;
}

.truncate-text {
  // max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::placeholder {
  color: #b3b3b3 !important;
}
.form-control:focus {
  border-color: $cyan-12;
  // box-shadow: 0 0 0 4px $cyan-12 !important;
}
.btn:active {
  transform: scale(0.95);
}
.pointer-cursor {
  cursor: pointer;
}
.user-initials {
  border-radius: 50%;
  color: white;
  background-color: var(--bs-blue_color);
}
@import "~bootstrap/scss/bootstrap";
// @import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
