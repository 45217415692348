.no-margin-padding {
  margin: 0;
  padding: 0;
}
/* .btn {
  background-color: white;
  border: none;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: white;
  border-color: none; 
} */
/* .react-calendar {
  border: 1px solid #d4d4d4 !important;
  border-radius: 10px !important;
 
}
.react-calendar__tile--active {
  background: #2e3192 !important;
}
.react-calendar__tile--now {
  background: #2e3192 !important;
  color: white !important;
}
.react-calendar__tile--hasActive {
  background: #2e3192 !important;
  color:white;
}
.react-calendar__navigation button {
  color:#2e3192 !important;
}
.popover {
  border-style: none !important;
  border-radius: 10px !important;
}
.react-calendar, .react-calendar *, .react-calendar *:before, .react-calendar *:after {
  font-family: 'Ubuntu' !important;
 
} */
