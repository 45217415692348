.monthly-table > thead > tr > th,
.monthly-table > tbody > tr > td {
  text-align: center;
  vertical-align: middle;
  
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 0px;
  justify-content: center;
  
}
.monthly-table > thead > tr > th:last-child,
.monthly-table > tbody > tr > td:last-child {
  border-right: none;
}
.monthly-table>thead>tr:first-child{
  border-bottom: 10px solid #e6e6e6;

}