@import "./../../scss/variables.scss";
.option-heading-container{
    cursor: pointer;
    
    &:hover{
        background-color: #f5f5f5;
    }
}
.area-name-td{
    text-align: left !important;
    padding: 10px 10px !important;
    background-color: #e6e6e6 !important;
}
.report-title-header-sticky{
    position: sticky;
    top: 55px;
    left: 0px;
    right: 0px;
    z-index: 1;
    background-color: white;
    border-bottom: 1px solid #e6e6e6;

}
.report-sublink-list-item{
    &:hover{
        background-color: #f5f5f5;
    }
}

.report{
    &__header{
        background-color: #f5f5f5;
        padding-left: var(--bs-gutter-x, 15.75rem);
        padding-right: var(--bs-gutter-x, 15.75rem);
        height: 70px;
        
    }
    
}
.swims{
    &__header{
        background-color: #f5f5f5;
        padding-left: var(--bs-gutter-x, 15.75rem);
        padding-right: var(--bs-gutter-x, 15.75rem);
        height: 70px;
        
    }
    
}
.report-filter-container{
    background-color: $cyan-6;
    
}

.report-table{
    thead>tr{
        background-color: #f2f6ff;
        th{
            font-size: 11px;
            color: #048fc2;
            vertical-align: middle;
        }
        
        


    }
    tbody>tr{
        border-bottom: 1px solid #e6e6e6;
        td{
        font-size: 11px;
        vertical-align: middle;


    }
    
}
        
    
}