@import "./../../scss/variables.scss";

.edit-location-option {
  transition: linear 0.1s;
}
.edit-location-option:hover {
  border-right: 4px solid #afafaf;
  /* background-color: whitesmoke; */
}
.edit-location-option-active {
  border-right: 4px solid #afafaf;
  /* background-color: whitesmoke; */
}

.sidemenuIcon {
  border-radius: 10px;
  color: grey;
  background-color: whitesmoke;
  font-size: 40px;
  padding: 8px;
  margin-right: 10px;
}

@media (min-width: 975px) {
  .resSidemenu {
    padding: 0px !important;
  }
}
@media (max-width: 974px) {
  .resSidemenu {
    padding: 12px !important;
  }
}

.search-grd-btn {
  background: linear-gradient(
    to right,
    rgba(17, 146, 138, 0.9),
    rgba(29, 165, 212, 0.9)
  );
  border-radius: 25px;
  font-size: 10px;
}
/* .pac-container:after {
  background-image: none !important;
  height: 0px;
} */

// .location-dropdown {
//   background-color: rgba($color: $highlighted, $alpha: 0.2);
// }
// .location-dropdown:hover,
// .location-dropdown:focus {
//   background-color: rgba($color: $highlighted, $alpha: 0.4) !important;
// }
// .location-dropdown > div {
//   color: black !important;
// }
// .location-dropdown::after {
//   color: black !important;
// }
.archive-btn {
  font-size: 10px;
}
.archive-btn::after {
  color: black !important;
}
.time-checkbox > input {
  width: 13px !important;
  height: 13px !important;
}
.time-checkbox > input:checked {
  background-color: black !important;
}
.time-checkbox > input:focus {
  box-shadow: rgba(0, 0, 0, 0.5);
}
.area-container {
  border-radius: 10px !important;
}
.notification-circle {
  background-color: rgba($color: $highlighted, $alpha: 0.5);
  height: 100%;
  width: 50px;
  position: absolute;
  border-radius: 50%;
  margin-left: -30px;
  z-index: 1;
}
.new-location-input::placeholder {
  color: red;
}

.location-time-picker > input {
  border-radius: 30px;
}
.dot {
  height: 14px;
  width: 14px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
}
.box {
  height: 12px;
  width: 12px;
  background-color: rgb(7, 47, 160);

  display: inline-block;
  margin-right: 10px;
}


  .locations-table > tbody, thead{

    &>tr{
    border-bottom: 1px solid #e6e6e6 !important;
  }
  
  }

  
  .report-form{
    background-color: $cyan-6;

  }