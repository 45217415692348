

/* .form-check-input:checked {
  background-color: #2e3192;
  border-color: #2e3192;
} */

.startIcon,
.endIcon {
  padding: 0px 4.5px;
  /* background-color: whitesmoke !important; */
}
.startIcon {
  margin-left: 5px;
}

/* .endIcon {
  padding-right: 9px;
  padding-left: 0px;
} */
.form-select {
  background-color: white !important;
  border-color: transparent !important;
}
.inputGroupForm {
  background-color: whitesmoke;
}
.whitePlaceholder::placeholder {
  color: rgba(225, 225, 255, 0.7) !important;
}
.blackPlaceholder::placeholder {
  color: red !important;
}

.QS-placeholder::placeholder {
  color: #b3b3b3 !important;
}
.QS-placeholder {
  cursor: text !important;
}
.noShadow:focus{
  box-shadow: none;

}