$primary: #1da5d4;
$secondary: #f79523;
$success: #3e8d63;
$info: #2e3192;
$warning: #b8c924;
$danger: #d62518;
$light: #f8f9fa;
$dark: #343a40;

//Custom colors
$blue_color: #1da5d4;
$green_color: #11928a;
$dark_orange: #f79523;
$light_orange: #f79523;
$highlighted: #2e3192;
$plain: #ffffff;
$no_color: transparent;
$cyan-12: #ebf7fd;
$cyan-6: #ecf8fe;
$grey: #e6e6e6;
$white: #ffffff;
