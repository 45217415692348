
.daily-table > thead > tr > th,
.daily-table > tbody > tr > td {
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 0px;
  justify-content: center;
  max-width: 200px;
  width: 180px;
}
.daily-roster-item{
  color: white;
  height: 55px;
  &:hover .daily-roster-item-overlay{
    opacity: 1;
  }
}
.daily-roster-item-overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  padding: 0.25rem;
  
}
.daily-roster-item-wrapper{
  padding: 0.10rem;
  border-radius: 4px;
}
.daily-table>thead>tr:first-child{
  border-bottom: 10px solid #e6e6e6;

}