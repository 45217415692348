@import "./../../scss/variables.scss";
.overlaybg-business-select {
  background: linear-gradient(
      to top,
      rgba($color: $blue_color, $alpha: 0.9),
      rgba($color: $blue_color, $alpha: 0.9)
    ),
    url(./Img/signinbg.jpg);
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
// .h-100 {
//   height: 100vh !important;
// }
.styleBusinesslist {
  background: linear-gradient(to right, #f5f5f5, white, #f5f5f5);
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}
.styleBusinesslist:hover {
  background: linear-gradient(180deg, #f79523, #f05a24);
  color: white !important;
}

@media only screen and (max-width: 767px) {
  .scrollBusinesslist {
    padding: 15px;
    height: 150px;
    overflow-y: scroll !important;
    overflow: hidden;
  }
}
@media only screen and (min-width: 768px) {
  .scrollBusinesslist {
    padding: 15px;
    height: 400px;
    overflow-y: scroll !important;
    overflow: hidden;
  }
}

.selection-new-business{
  &:hover{
    border: 1px solid #e6e6e6;
    background-color: #e6e6e6;
  }
}
.business-container{
  &:hover{
    border: 1px solid #e6e6e6;
    background-color: #e6e6e6;
  }
}