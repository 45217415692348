@import "./../../scss/variables.scss";
.overlaybg {
  background: linear-gradient(
      to top,
      rgba($color: $blue_color, $alpha: 0.9),
      rgba($color: $blue_color, $alpha: 0.9)
    ),
    url(./Img/signinbg.png);

  // background-position: bottom !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  min-width: 30rem;
}

.fullscreen {
  height: 100vh !important;
}

.sign-in {
  &-input-icon {
    color: $grey !important;
    height: 1.5rem;
    width: 1.5rem;
  }
  &__input {
    height: 2.5rem;
  }
  &__login-btn {
    color: white !important;
  }
  &__forgot-password {
    color: white !important;
  }
}
