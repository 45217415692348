@import "./../../scss/variables.scss";
// @import "~bootstrap/scss/bootstrap";
@import "./../../scss/index.scss";
.generic-input {
  &__label {
    border-radius: 10px;
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: normal;
    overflow-wrap: break-word;
  }
  &__input {
    font-size: 13px;
    border-radius: 5px;
  }
  &__invalid {
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(#f44336, 0.7);
  }
}

.generic-input__input:focus {
  border-color: $cyan-12;
  box-shadow: 0 0 0 0.25rem rgba($cyan-12, 0.7) !important;
}

.rc-time-picker {
  &-input {
    @extend .generic-input__input;
    @extend .form-control;
    @extend .border;
    height: auto;
  }
  &-label {
    @extend .generic-input__label;
  }
}
.invalid-time {
  input {
    box-shadow: 0 0 0 1px rgba(#f44336, 0.7);
  }
}
.calendar-popover {
  width: auto;
  max-width: none;
  border: none;
}
