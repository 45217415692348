.gradient-btn {
  background: rgb(17, 146, 138);
  background: linear-gradient(180deg, #f79523, #f05a24);
}
.btn {
  border-radius: 5px;
}

.buttonHover:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: white !important;
}
