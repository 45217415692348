@import "./../../scss/variables.scss";
/* .allBorders {
  border: rgba(0, 0, 0, 0.2) solid 1px;
}
.shiftBox {
  border-radius: 10px;
  padding: 10px;
  margin-top: 5px;
  font-size: 1.5vmin;
  position: relative;
}
.shiftBox:hover > .shiftBoxOverlay {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.shiftBoxOverlay {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  display: none;
  border-radius: 10px;
}
.shiftBoxOverlayText {
  text-align: center;
}
.staffColumn {
  background-color: white;
  overflow: auto !important;
  height: 100%;
}
.schedule-container {
  height: 85vh !important;
  background-color: white;
  border-radius: 10px;
  
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shiftColumn {
  overflow: auto;
}

.popover {
  max-width: inherit !important;
}

.date-column {
  height: 100%;
  border: rgb(180, 178, 178) 1px solid;
  min-width: 200px;
}
.dotted-border {
  border: black 1px dotted;
  border-radius: 5px;
}
.date-row {
  display: inline-flex;
  height: 100%;
}

.hidden {
  display: none;
} */

.staff-column,
.shift-column {
  overflow-y: scroll !important;
  /* overflow: hidden; */
  height: 100%;
}
.staff-column::-webkit-scrollbar,
.shift-column::-webkit-scrollbar,
.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.form-check-input:checked {
  background-color: $blue_color !important;
  border-color: $blue_color !important;
}
.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba($color: $blue_color, $alpha: 25%) !important;
}

.schedule-weekly-table > thead > tr > th {
  text-align: center;
  vertical-align: middle;
  // border: 1px solid black;
}

.schedule-weekly-table > tbody > tr > td {
  white-space: nowrap;
  vertical-align: middle;
  // border: 1px solid black;
}

.schedule-weekly-table {
  border-radius: 0.2rem;
  width: 100%;
  padding-bottom: 1rem;
  color: #212529;
  margin-bottom: 0;
}
.schedule-weekly-table th:first-child,
.schedule-weekly-table td:first-child {
  position: sticky;
  left: 0;
  color: #373737;
  background-color: white;
  // border: 1px solid black;
}
.first-col {
  background-color: white;
  width: 200px;
}
.rest-col {
  background-color: white;
  width: 200px;
}

.table > thead,
.table > tbody {
  border: none !important;
}
.shift-box {
  // background-color: green;
  color: white;
  display: flex;
  height: 100%;
  border-radius: 4px;

  cursor: pointer;
  &:hover {
    .shift-box-overlay {
      opacity: 1;
    }
  }
}
.shift-box-overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: green;
  border-radius: 4px;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

  transition: 0.5s ease;
  opacity: 0;
}
.shift-box-wrapper {
  // padding: 0.10rem;
  border-radius: 4px;
}
.shift-box-icon {
  color: black;
  &:hover {
    color: white;
  }
}
.copy-shift-modal-link {
  font-size: 12px;
  cursor: pointer;
}
.copy-shift-time-item {
  font-size: 13px;
  min-height: auto !important;
  margin-bottom: 0px;
}
.copy-shift-list-item.active {
  background-color: $cyan-12 !important;
  border-color: $blue_color !important;
  color: black !important;
}
