@import "./../../scss/variables.scss";
.FormStyle {
  border-radius: 20px;
}
.rightColStyle {
  background-color: white;
  border-radius: 20px;
}
.overlaybg-sign-up {
  background: linear-gradient(
      to top,
      rgba($color: $blue_color, $alpha: 0.9),
      rgba($color: $blue_color, $alpha: 0.9)
    ),
    url(./Img/signinbg.jpg);
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
// .h-100 {
//   height: 100vh !important;
// }

.icons {
  padding: 14px 14px 14px 0px !important;
}
@media screen and (max-width: 992px) {
  .respverifyIcon {
    font-size: 40px !important;
  }
}

.verify-option:hover {
  background-color: $dark_orange !important;
  svg,
  div {
    color: white !important;
  }
}

.learn-more-link {
  text-decoration: underline !important;
  color: white !important;
}

.sign-up-icon {
  background-color: whitesmoke;
}
