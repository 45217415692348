@import "./../../scss/index.scss";
.calendar-dropdown-btn {
  button {
    @extend .border;
    width: 100%;
    text-align: justify !important;
    position: relative;
  }

  button::after {
    // float: right;
    // margin: auto;
    // display: none;
    position: absolute;
    right: 0;
    top: 0.7rem;
  }
  div > a {
    font-size: 14px;
  }
}
.QS-calender {
  height: 200px !important;
  min-height: 200px !important;
  width: 200px;
  :not(.Calendar__footer) button {
    font-size: 10px !important;
  }

  .Calendar__header {
    padding: 1em 1.6em;
    .Calendar__monthArrowWrapper {
      span {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }
  .Calendar__monthYear {
    button {
      font-size: 13px !important;
    }
  }
  .Calendar__sectionWrapper {
    // min-height: fit-content;
    .Calendar__section {
      min-height: fit-content;
      padding-left: 1.6em;
      padding-right: 1.6em;
      div > div {
        font-size: 10px;
        min-height: fit-content;
        margin: 0;
      }
    }
  }
  .Calendar__weekDays {
    font-size: 10px;
    margin-bottom: 0;
    padding: 0.6em 1.6em;
  }
  .Calendar__monthSelectorAnimationWrapper {
    .Calendar__monthSelectorWrapper {
      .Calendar__monthSelector {
        .Calendar__monthSelectorItem:not(:nth-child(-n + 3)) {
          margin-top: 1em;
        }
      }
    }
  }
}
