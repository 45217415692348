@import "./../../scss/variables.scss";
.timesheet-container {
  min-width: 700px;
  height: calc(100vh - 70px);
}
.resizer-handle {
  background-color: red;
  width: 100%;
  cursor: ns-resize;
  height: 15px;
  line-height: 15px;
  padding: 0;
  margin: 0;
  text-align: center;
  color: white;
  position: absolute;
  bottom: 0;
}

.react-resizable {
  position: relative;
  overflow-y: auto;
}
.react-resizable-handle {
  position: absolute;
  width: 100%;
  height: 16px;
  bottom: 0;
  right: 0;
  left: 0;
  // background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAJFJREFUOE/N0rENwjAURdETiRXCQuwCKSioGYAFoAhiFuZBYgYKZIlIwTG2JTf80vK91nv+ncbpGnn/IVhhxBHPykhrnDBMEbY4YINHQdLjjhvO8w5qJF9weCguMSdZwClBOEtJkvAvQSx5zTPH/eT2YI/dB7jikiq3tEhBEiYJ5yJUrsPyF6rB6WIpQlHYLHgDri0aFU9NnYMAAAAASUVORK5CYII=");
  background-color: grey;
  background-position: center;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: ns-resize;
}

.timesheet-table {
  &__header {
    position: sticky;
    top: 0;
    background-color: #fff;
    // border-top: none;
  }
}

.qs-input-wrapper {
  :focus {
    border-color: $cyan-12;
    box-shadow: 0 0 0 4px $cyan-12;
  }
}
.qs-select {
  border: 1px solid #ebebec !important;
  // border-color: #ebebec !important;
  font-size: 13px;
  :focus {
    border-color: $cyan-12 !important;
    box-shadow: 0 0 0 2px $cyan-12 !important;
    // outline: none;
  }
}

.qs-input {
  background-color: #fff !important;
}
.summary-wrapper {
  background-color: $cyan-12;
}
.export-table-header {
  font-size: 12px;

  :not(:first-child) {
    width: 100px;
    text-align: center;
  }
}
.export-table {
  //   :not(caption) > * > * {
  //     border-bottom-width: 0px !important;
  //   }
  tbody {
    tr {
      :not(:first-child) {
        text-align: center;
      }
      td {
        font-size: 12px;
      }
    }
  }
}
.expanded-row-custom {
  background-color: whitesmoke !important;
}

.timesheet-table__header {
  th {
    font-size: 12px;
  }
}
.timesheet-table__row {
  font-size: 12px;
}
.timesheet-details-row:hover {
  background-color: #ebebec;
}
