.switch-overlay {
  background-color: black;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 50%;
  animation-name: slideRight;
  animation-duration: 2s;
}
.switch-wrapper {
  position: relative;
}

.switch-enter {
  left: 0px;
}
.switch-leave {
  right: 0px;
}

.react-switch-handle {
  width: 15px !important;
  height: 15px !important;
  top: 2px !important;
}
.react-switch-bg {
  margin: 0px !important;
}
