.container {
  //   background-color: rgba($color: --highlighted, $alpha: 1);
}
.QS-Select {
  box-shadow: none;
  border: none;
  &::placeholder {
    color: #b3b3b3;
  }
  &__placeholder {
    color: #b3b3b3 !important;
  }
  &:hover {
    border: none;
    box-shadow: none !important;
  }
  &__control {
    padding-left: 10px;
    border: 1px solid #ebebec !important;
  }

  &__control--is-focused {
    border: none;
    box-shadow: none !important;
  }
  &__value-container {
    padding: 0 !important;
  }
  &__dropdown-indicator {
    color: black;
  }
  &__menu {
    z-index: 100000 !important;
  }
  &__option {
    font-size: 12px !important;
  }
  
}
